:root {
  --c-white: #ffffff;
  --c-black: #474747;
  --c-grey: #8e9598;

  --c-green: #0c5860;
  --c-teal: #31989e;
  --c-blue: #3574b6;
  --c-purple: #481f7c;
  --c-rose: #cd546c;
  --c-red: #ee6047;
  --c-copper: #ad5e47;
  --c-yellow: #f2c118;
}

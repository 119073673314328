@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.wrap {
  min-height: calc(100vh - 93px - 137px);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fffbed;
}

@media (min-width: 768px) {
  .wrap {
    min-height: calc(100vh - 93px - 81px);
  }
}

.icon {
  width: 100%;
  max-width: 100px;
  display: block;
  animation: rotate 3s 0s infinite;
}

.icon svg {
  display: block;
}

body {
  /* margin: 0; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  max-width: 100%;
  display: inline-block;
  vertical-align: top;
}

.root {
  font-family: "Open sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  /* font-size: 18px; */
  line-height: 1.5;
  /* letter-spacing: -0.35px; */
  color: #333333;
  z-index: 1;
  position: relative;
}

.pageWrap {
  transition: 0.5s ease-out;
  background: var(--bg);
}

@media (min-width: 600px) {
  .root {
    font-size: 18px;
  }
}
